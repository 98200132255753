/***
*
*   DASHBOARD
*
**********/

import {useState, React, useEffect, Fragment} from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI,Form, Button, Label, Content, GroupCardsContainer } from 'components/lib';
import Axios from 'axios';
import Style from './dashboard.tailwind.js';
import { apiKeyNames } from '../../helper/enum';
import { GetStarted, Header, SubHeader, Link } from './getStarted.js';
import { Card as MUICard, CardContent, Typography, Box, Avatar,  useMediaQuery, useTheme } from '@mui/material';
import logo_image from './logo_image.png';
const _ = require('lodash');

export function Dashboard(props){

  const [frequency, setFrequency] = useState('day')
  const [period, setPeriod] = useState('1 week')
  const [perApiUsageChart, setPerApiUsageChart] = useState({loading: true});

  const [perApiUsageChartColor, setPerApiUsageChartColor] = useState(perApiUsageChart?.data?.colors || []);
 
  const messageText = `This is your dashboard to get you started. Please read the documentation `;
  const [apiTilesData, setApiTilesData] = useState(perApiUsageChart?.data?.colors || {});
  const [appUrlList, setAppUrlList] = useState([{"value": "all","label": "All"}]);
  const [appNameList, setAppNameList] =  useState([{"value": "all","label": "All"}]);
  const [appUrl, setAppUrl] = useState('all');
  const [appName, setAppName] = useState('all');
  const [groupCardsData, setGroupCardsData] = useState({});

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  let darkMode = user?.dark_mode || false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getPerAPIChartFilters  = async () => {
     

      let result = await Axios({

        url: '/api/dashboard/app-list-details',
        method: 'get',
        
      });
      setAppUrlList(result?.data?.data?.appUrlList || []);
      setAppNameList(result?.data?.data?.appNameList || []);
    };

    getPerAPIChartFilters();
    
    

  }, []);

 

  useEffect(() => {

    const getPerAPIChartData  = async () => {
      setPerApiUsageChart({loading: true});
      let  result = await Axios({

        url: '/api/dashboard/api_usage',
        method: 'get',
        params: {frequency: frequency, period: period, app_url: appUrl, app_name: appName}
        
      });
     
      setPerApiUsageChart({ data: result.data.data, loading: false });
      setPerApiUsageChartColor(result?.data?.data?.colors || []);
      if(result?.data?.data?.tilesData){
        const tilesData =  _.cloneDeep(result?.data?.data?.tilesData) || {};
        const groupedTilesData = {
          "casts-feed": {},
          "users-feed": {},
          "search": {},
          "metadata": {}
        }
       
        Object.keys(tilesData).forEach((endpoint) => {
          // console.log(endpoint.includes('feed'))
          
          if(endpoint.includes('casts-feed')){
            groupedTilesData["casts-feed"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('users-feed')){
            groupedTilesData["users-feed"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('search')){
            groupedTilesData["search"][endpoint] = tilesData[endpoint];
          }
          else if (endpoint.includes('metadata')){
            groupedTilesData["metadata"][endpoint] = tilesData[endpoint];
          }
      });
      setGroupCardsData(groupedTilesData);
      setApiTilesData(result?.data?.data?.tilesData || {});
      }
      
    };

    getPerAPIChartData();
    
    

  }, [frequency, period, appUrl, appName]);

 
  const handleFrequencyChange = (selectedFrequency) => {
    setFrequency(selectedFrequency?.value);
  };

  const handlePeriodChange = (selectedPeriod) => {
    setFrequency('day');
    setPeriod(selectedPeriod?.value);
  };

  const handleAppUrlChange = (selectedAppUrl) => {
    setAppUrl(selectedAppUrl?.value);
  };

  const handleAppNameChange = (selectedAppName) => {
    setAppName(selectedAppName?.value);
  };

  const handleStartClick = () => {
    window.open('https://playground.mbd.xyz/', '_blank');
  };

  const handleViewDocsClick = () => {
    window.open('https://docs.mbd.xyz', '_blank');
  };


  const frequencyOptions = [
    { value: 'day', label: '1 day' },
    // { value: 'minute', label: '15 min' },
    { value: 'hour', label: '1 hour' }
  ];

  // Options for Period select input
  const periodOptions = [
    // { value: '1 month', label: '1 month' },
    { value: '1 day', label: '1 day' },
    { value: '1 week', label: '1 week' },
    // { value: '3 months', label: '3 months' },
    // { value: 'all', label: 'All' }
  ];
  
  return (
    <Animate type='pop'>

      {/* <Message
        closable
        title="Welcome to 'mbd console!"
        text={messageText}
        endTextLink = 'https://docs.mbd.xyz/reference'
        endText = 'here.'
      /> */}

      <Header>
        <span style={{justifyContent: 'space-between', display: 'flex'}}>
          <Typography variant={isMobile ? "h6" : "h5"}  style = {{color : darkMode ? "#fff" : "#000"}} component="div">
            Welcome {user?.name}!
          </Typography>
          <Avatar src={logo_image} alt="Company Logo" style={{ width: isMobile ? 35 : 45, height: isMobile ? 35 : 45, paddingBottom: 5}}/>
          </span>
        </Header>
      <GetStarted  darkMode = {darkMode}/>

{/* {Object.keys(apiTilesData).length && (
  <Grid cols={Object.keys(apiTilesData).length + 1}>
    {Object.keys(apiTilesData).map((endpoint) => (
      <Stat
        key={endpoint} // Don't forget to add a unique key for each Stat component
        loading={apiTilesData?.loading}
        value={apiTilesData[endpoint]}
        label={endpoint}
        icon='refresh-cw'
      />
    ))}
  </Grid>
)} */}

<GroupCardsContainer groupedTilesData={groupCardsData} />

      <br/>

      <Card name='api_usages' title='Per API Usage'>
      <Grid cols={4}>
            <Form
            inputs={{
              frequency: {
                label: 'Frequency',
                type: 'select',
                required: true,
                default:{ value: 'day', label: '1 day' },
                options:  period !== '1 day' ? frequencyOptions.filter(option => option.value === 'day') : frequencyOptions
              }
            }}
            updateOnChange
            onChange= {handleFrequencyChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'Period',
                type: 'select',
                required: true,
                default:{ value: '1 week', label: '1 week' },
                options: periodOptions
              }
            }}
            updateOnChange
            onChange= {handlePeriodChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Name',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appNameList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppNameChange}
          />
          <Form
            inputs={{
              frequency: {
                label: 'App Url',
                type: 'select',
                required: true,
                default:{"value": "all","label": "All"},
                options: appUrlList || [{"value": "all","label": "All"}]
              }
            }}
            updateOnChange
            onChange= {handleAppUrlChange}
          />
          </Grid>
       
        
        <Card>
        <Chart
          type='line'
          legend
          loading={ perApiUsageChart.loading }
          data={perApiUsageChart.data }
          color={perApiUsageChartColor}
        />
        </Card>
      </Card>

      <Feedback />

    </Animate>
  );
}
