import React from 'react';
import { Grid, Typography, Tooltip, useMediaQuery, useTheme } from '@mui/material';

export const GroupCardsContainer = ({ groupedTilesData }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode =  user.dark_mode ? true : false;

  const calculateCardWidth = () => {
    const numCards = Object.keys(groupedTilesData).length;
    const screenWidth = window.innerWidth;
    const spacing = 2 * numCards; 
    const cardWidth = (screenWidth - spacing) / numCards;
    return Math.max(cardWidth, 250); 
  };

  return (
    <Grid
      container
      spacing={2.5}
      justifyContent="center"
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : undefined}
      wrap="wrap"
    >
      {Object.entries(groupedTilesData).map(([groupName, groupData]) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={groupName} style={{ width: calculateCardWidth() }}>
          <Tooltip
            title={
              <React.Fragment>
                {Object.entries(groupData).map(([endpoint, count]) => (
                  <div key={endpoint}>
                    {endpoint}: {count}
                  </div>
                ))}
              </React.Fragment>
            }
            arrow
          >
            <div
              style={{
                border: '1px solid transparent',
                borderRadius: '5px',
                padding: '12px',
                cursor: 'pointer',
                transform: 'perspective(1000px) rotateY(5deg)',
                transition: 'transform 0.3s ease',
                whiteSpace: 'nowrap', // Prevent text wrapping
                backgroundColor: isDarkMode ? '#1e293b' : '#ffffff', // Gray in dark mode, white otherwise
                minHeight: '80px',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'perspective(1000px) rotateY(0deg)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'perspective(1000px) rotateY(5deg)';
              }}
            >
              <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
                {Object.values(groupData).reduce((acc, curr) => acc + curr, 0)}
              </Typography>
              <Typography variant="body2" align="center" style={{
                  color: '#94a3b8'
                }}
              >
                {groupName}
              </Typography>
            </div>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};