/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, Logo, Link, Button, Content, ClassHelper, useNavigate } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props){

  // console.log("HomeNav:",window.location.pathname);
  let urlPath = window.location.pathname;
  const queryParameters = new URLSearchParams(window.location.search)
  const login_to = queryParameters?.get("login_to") || null;
  // console.log("HomeNav:",login_to);
  let pattern = /\/signin/;
  let isSignin = pattern.test(urlPath);

  const navigate = useNavigate();

  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });

  return(
    <section className={ css }>
      <Content>

        <Logo className={ Style.logo }/>
        <nav className={ Style.nav }>

          {/* <Link url='/pricing' text='Pricing' className={ Style.link } color='white'/> */}
          {/*  : */}

          { context.user?.token ?
                     login_to === 'readme'? <Button small goto='/dashboard' text='Dashboard' className={ Style.button } />:navigate('/dashboard') :
            <Fragment>
              {/* <Link url='/signin' text='Sign In' className={ Style.link } color='white'/> */}
              {
                isSignin ?  <Button small goto='/signup' text='Sign Up'  className='inline-block' /> : <Button small goto='/signin' text='Sign In'  className='inline-block' />
              }
              {/* <Button small goto='/signup' text='Sign Up' className='inline-block' /> */}
            </Fragment>
          }
        </nav>
      </Content>
    </section>
  );
}